
import { Container, Grid, Snackbar, InputLabel, MenuItem, Select, FormControl, SelectChangeEvent, Checkbox, ListItemText } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import ResultView from '../subPages/ResultView';
import { API_PATH, appContext } from '../App';
import axios from 'axios';
import { CustomButton } from '../helpers/CustomStyles';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import AppMenu from '../subPages/AppMenu';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


export interface Guide {
    id: number,
    guideName: string,
    age: number,
    price: number,
    shortDescription?: string,
    description: string,
    languages: string[],
    photos: string[],
    available: boolean,
    nextAvailableDate: null | string,
    gender?: null | string,
    location?: null | string,
    hide?: null | boolean
}


function SearchReasult() {
    const { languageList, searchResults, setSearchResults } = useContext<{ languageList: string[], searchResults: Guide[] | null, setSearchResults: React.Dispatch<React.SetStateAction<Guide[] | null>> }>(appContext);
    const [searchLanguage, setSearchLanguage] = useState<string[]>([]);
    const [searchDate, setSearchDate] = useState('');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();


    useEffect(() => {
        readUrlParms()
        return () => {
            //setSearchResults(null)
        };
    }, []);
    function readUrlParms() {
        let _date = searchParams.get("date");
        let _language = searchParams.get("language");
        if (_date == null || _language == null) {
            _language = (languageList[0] ?? "English")
            const tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
            const yyyy = tomorrow.getFullYear();
            let mm: string | number = tomorrow.getMonth() + 1; // Months start at 0!
            let dd: string | number = tomorrow.getDate();

            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;
            _date = (dd + '/' + mm + '/' + yyyy)

        }
        setSearchLanguage(_language.split(','))
        setSearchDate(_date)
        console.log(searchResults)
        if (searchResults == null || searchResults.length == 0) {
            getData(_language, _date)
        }
    }

    useEffect(() => {
        if (searchResults != null && searchResults.length > 0) {
            return
        }
        if (searchLanguage.length > 0 && searchDate.length > 0) {
            submitSerch()
        }
    }, [searchLanguage, searchDate])

    function submitSerch() {
        if (searchDate == '') {
            setErrorMessage("Invalid Date")
            return;
        }
        if (searchLanguage.length == 0) {
            setErrorMessage("Invalid Language")
            return;
        }
        let params = new URLSearchParams([]);
        params.set("date", searchDate);
        params.set("language", searchLanguage.join(','));
        setSearchParams(params)
        getData(searchLanguage.join(','), searchDate);
    }
    function getData(lang: string, date: string) {
        setSearchResults(null)
        axios.get(API_PATH + '/travel/search?laguage=' + lang + '&date=' + date)
            .then(function (response) {
                setSearchResults(response.data)
            })
            .catch(function (error) {
                console.log(error);
            })
    }
    const handleChange = (event: SelectChangeEvent<typeof searchLanguage>) => {
        const {
            target: { value },
        } = event;
        setSearchLanguage(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        setSearchResults(null)
    };

    function sortGuide(data: Guide[]): Guide[] {
        return data.slice().sort((a, b) => { return a.available === b.available ? 0 : a.available ? -1 : 1; });
    }

    return (
        <>
            <AppMenu isHome={true} />
            <Container>
                <Grid container spacing={2} style={{ paddingTop: "20px" }}>
                    <Grid item xs={12}>
                        <FormControl fullWidth size='small'>
                            <InputLabel id="demo-simple-select-label">Language</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={searchLanguage}
                                multiple
                                label="Language"
                                onChange={handleChange}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}
                            >
                                {languageList.map((n, i) => {
                                    return <MenuItem key={i} value={n}>
                                        <Checkbox checked={searchLanguage.indexOf(n) > -1} />
                                        <ListItemText primary={n} />
                                    </MenuItem>
                                })}

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={8}>
                        <FormControl fullWidth size='small'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    disablePast
                                    value={dayjs(searchDate, 'DD/MM/YYYY')}
                                    onChange={(x) => {
                                        setSearchDate((x?.format("DD/MM/YYYY")) ?? "");
                                        setSearchResults([])
                                    }}
                                    slotProps={{ textField: { size: 'small' } }}
                                    format='DD/MM/YYYY'
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <CustomButton variant="contained" size='large' fullWidth onClick={submitSerch} component={"label"}
                            tabIndex={-1}
                            startIcon={<TravelExploreIcon />} >Search</CustomButton>
                    </Grid>
                    <Grid item xs={12}>
                        <ResultView results={(searchResults ? sortGuide(searchResults) : [null, null])} date={searchDate} />
                    </Grid>
                </Grid>
                <Snackbar
                    open={errorMessage != null}
                    autoHideDuration={6000}
                    onClose={() => { setErrorMessage(null) }}
                    message={errorMessage}
                />
            </Container>
        </>
    );
}

export default SearchReasult;

