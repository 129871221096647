import { Grid, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function FindGuide() {
    const navigate = useNavigate();
    return (
        <Grid container style={{ minHeight: "300px" }} >
            <Grid item xs={12} sm={5} justifyContent={"center"} display={"flex"} style={{ backgroundColor: "rgb(33, 62, 140)", color: "#fff" }} >
                <Stack spacing={3} style={{ margin: "auto" }} flexGrow={1} onClick={() => { navigate('/') }} >
                    <Typography variant="h3" style={{ margin: "20px auto", textAlign: "center" }} gutterBottom> Find a guide </Typography>
                    <Typography variant="body2" style={{ margin: "20px auto", textAlign: "center" }} gutterBottom> Enjoy this amazing land </Typography>
                </Stack>
            </Grid>
            <Grid item xs={7} sx={{ display: { xs: "none", sm: "flex" }, backgroundImage: "url(/website/find-guide.jpeg)" }} className='landing-find-guide-img'></Grid>
        </Grid>
    );
}