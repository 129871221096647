import { Box, Button, Container, Grid, Skeleton, ThemeProvider, Typography } from "@mui/material";
import { Guide } from "./SearchReasult";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import axios from "axios";
import { API_PATH } from "../App";
import GuideImages from "../subPages/GuideImages";
import BookNow from "../subPages/BookNow";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import GuideInfo from "../subPages/GuideInfo";
import AppMenu from "../subPages/AppMenu";

function GuideView() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [guideInfo, setGuideInfo] = useState<Guide | null>(null);
  const [date, setDate] = useState<string>("");
  useEffect(() => {
    let _id = searchParams.get("id");
    let _date = searchParams.get("date");
    if (_date != null) {
      setDate(_date)
    }
    if (_id != null) {
      getGuideAllInfo(_id, _date ?? "")
    }
    return () => {
      setGuideInfo(null)
    };
  }, [])
  function getGuideAllInfo(id: string, dateString: string) {
    axios.get(API_PATH + '/travel/guide?id=' + id + "&date=" + dateString)
      .then(function (response) {
        setGuideInfo(response.data)
      })
      .catch(function (error) {
        console.log(error);
      })
  }
  return (
    <Container>
      <AppMenu />
      <Grid container style={{ paddingTop: "10px" }}  >
        {(guideInfo == null) && (<>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={500} />
            <Box sx={{ pt: 0.5 }}>
              <Skeleton />
              <Skeleton width="60%" />
              <Skeleton width="80%" />
              <Skeleton width="50%" />
              <Skeleton width="90%" />
            </Box>
          </Grid >
        </>)}
        {(guideInfo != null) && (<GuideInfo guideInfo={guideInfo} date={date} />)}
      </Grid>
    </Container>)
}

export default GuideView;


