import { Typography, Grid, Box, List, ListItem, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AppMenu from '../subPages/AppMenu';
import FindGuide from '../website/Landing/FindGuide';
import WelcomeImage from '../website/Landing/WelcomeImage';


function AboutPage() {
  const navigate = useNavigate();

  return (
    <Grid container  >
      <AppMenu />

      <WelcomeImage />
      <Grid item xs={12}>
        <Grid container padding={"40px 20px"} style={{ maxWidth: "1000px", margin: "0px auto" }}>
          <Grid item xs={12}>
            <Typography variant="body2" gutterBottom>
              "Deep Tour" is an online portal that offers comprehensive information and services related to travel and tourism. It typically provides:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" gutterBottom>
              <ul>
                <li> Destination Guides: Detailed information about various travel destinations, including popular attractions, cultural insights, local cuisine, and travel tips.</li>
                <li>Travel Itineraries: Sample itineraries for different types of travelers (e.g., families, solo travelers, adventure enthusiasts) to help plan their trips effectively.</li>
                <li>Booking Services: Options for booking flights, accommodations, tours, and activities directly through the portal.</li>
                <li>User Reviews and Ratings: Feedback from travelers who have visited destinations or used services, helping others make informed decisions.</li>
                <li>Travel News and Updates: Information on travel advisories, events, and special offers related to travel.</li>
              </ul>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" gutterBottom>
              Is there something specific you're looking for or curious about regarding "Deep Tour"?
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <FindGuide />
    </Grid>
  );
}

export default AboutPage;


