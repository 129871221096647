import { Typography, Grid, Chip, Stack } from "@mui/material";
import { Guide } from "../pages/SearchReasult";
import GuideImages from "../subPages/GuideImages";
import BookNow from "../subPages/BookNow";
import Lightbox, { SlideImage } from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import React from "react";

function GuideInfo(props: { guideInfo: Guide | null, date: string, modalView?: boolean }) {
  const { guideInfo, date, modalView } = props

  const [openGallery, setOpenGallery] = React.useState(false);
  const [galleryImages, setGalleryImages] = React.useState<SlideImage[]>([]);
  let animationDuration = 500;
  let maxZoomPixelRatio = 1;
  let zoomInMultiplier = 2;
  let doubleTapDelay = 300;
  let doubleClickDelay = 300;
  let doubleClickMaxStops = 2;
  let keyboardMoveDistance = 50;
  let wheelZoomDistanceFactor = 100;
  let pinchZoomDistanceFactor = 100;
  let scrollToZoom = false;

  function openSlide(n: number) {
    if (guideInfo) {
      let reordered = guideInfo.photos.slice(0, n).concat(guideInfo.photos.slice(n))
      setGalleryImages(reordered.map(x => { return { src: x } }))
      setOpenGallery(true)
    }
    console.log("clicked : " + n)
  }

  if (guideInfo == null) {
    return <></>
  }
  return (<Grid container style={modalView ? { padding: "10px ", background: "#ddd", minHeight: "100vh" } : {}}>
    <Grid item xs={12}>
      <Grid container style={modalView ? { margin: "5px auto", maxWidth: "500px", background: "#eee", padding: "15px", borderRadius: "15px" } : {}} >
        <Grid item xs={12} style={{ borderRadius: "10px", }}>
          <GuideImages data={guideInfo} onClicked={openSlide} />
        </Grid>
        <Grid item xs={12} style={{ marginTop: "45px" }}>
          <Grid container spacing={1} style={{ background: "#fff", borderRadius: "10px", padding: "10px 10px 15px 10px", marginLeft: "-4px" }}>
            <Grid item xs={12} >
              {(guideInfo.hide || !guideInfo.available) ? (<></>) : (<BookNow data={guideInfo} bookingDate={date} fullWidth />)}
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1}>
                <Typography variant="body2" color="text.secondary" style={{ margin: "auto 5px" }}> I speak: </Typography>
                {guideInfo.languages.map((n, i) => {
                  return <Chip key={i} label={n} size="small" />
                })}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={6}>
                  <Stack direction="row" spacing={1}>
                    <Typography variant="body2" color="text.secondary" style={{ margin: "auto 5px" }}> Location:  </Typography>
                    <Chip label={guideInfo.location ?? "-"} size="small" />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="row" spacing={1}>
                    <Typography variant="body2" color="text.secondary" style={{ margin: "auto 5px" }}>Age : </Typography>
                    <Chip label={guideInfo.age} size="small" />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={6}>
                  <Stack direction="row" spacing={1}>
                    <Typography variant="body2" color="text.secondary" style={{ margin: "auto 5px" }}> Rate:  </Typography>
                    <Chip label={guideInfo.price} size="small" />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="row" spacing={1}>
                    <Typography variant="body2" color="text.secondary" style={{ margin: "auto 5px" }}>Sex : </Typography>
                    <Chip label={guideInfo.gender} size="small" />

                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="text.primary" dangerouslySetInnerHTML={{ __html: guideInfo.description }} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} style={{ flex: 1 }}>
      <Lightbox
        open={openGallery}
        close={() => setOpenGallery(false)}
        slides={galleryImages}
        plugins={[Zoom]}
        animation={{ zoom: animationDuration }}
        zoom={{
          maxZoomPixelRatio,
          zoomInMultiplier,
          doubleTapDelay,
          doubleClickDelay,
          doubleClickMaxStops,
          keyboardMoveDistance,
          wheelZoomDistanceFactor,
          pinchZoomDistanceFactor,
          scrollToZoom,
        }}
        render={{
          buttonPrev: () => null,
          buttonNext: () => null,
        }}
      />
    </Grid>
  </Grid >)
}

export default GuideInfo;


