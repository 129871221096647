import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { appContext } from "../App";
import React from "react";


export interface MessageBody {
  isError: boolean;
  title: string;
  message: JSX.Element;
  action?: JSX.Element;
}
function Messages() {
  const { message, setMessage } = React.useContext<{
    message: MessageBody | null,
    setMessage: React.Dispatch<React.SetStateAction<MessageBody | null>>
  }>(appContext);

  function handleClose() {
    setMessage(null)
  }

  return (<Dialog
    open={message != null}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {message?.title}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {message?.message}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      {(message?.action != null) && (message.action)}
      {(message?.action == null) && (<Button onClick={handleClose} autoFocus> Close </Button>)}
    </DialogActions>
  </Dialog>)
}

export default Messages;