import { Box, Grid, Button } from "@mui/material";
import { Guide } from "../pages/SearchReasult";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { useTheme } from '@mui/material/styles';
import ReactSwipe from "react-swipe";
import { CustomNameLabel } from "../helpers/CustomStyles";
import ImageLoader from "./ImageLoader";


function GuideImages(props: { data: Guide, minimalView?: boolean, onClicked?: (n: number) => void }) {
  const { data, minimalView, onClicked } = props

  const theme = useTheme();

  let reactSwipeEl: ReactSwipe | null;


  const handleNext = () => {
    reactSwipeEl?.next()
  };

  const handleBack = () => {
    reactSwipeEl?.prev()
  };
  const images = (data.photos.length == 2) ? data.photos.concat(data.photos) : data.photos

  return (
    <>
      <Grid item xs={12} >
        <ReactSwipe
          className={(minimalView) ? "carousel" : "img-gallery-carousel"}
          swipeOptions={
            {
              startSlide: 0,
              speed: 400,
              auto: 3000,
              continuous: true,
              disableScroll: false,
              stopPropagation: false,
              callback: function (index, elem) { },
              transitionEnd: function (index, elem) { }
            }}
          ref={el => (reactSwipeEl = el)}
        >
          {images.map((n, i) => {
            return (<div key={i} style={{ width: "100%", flex: 1, alignContent: "center", textAlign: "center" }}  >
              <ImageLoader src={n} onClick={() => { if (onClicked) { onClicked(i) } }}
                maxHeight={!minimalView}
                extra={(minimalView) ? <></> : <span style={{ position: "absolute", top: "13px", padding: "10px", right: "10px", background: "rgba(255, 202, 40,0.5)", borderRadius: "50px", fontSize: "12px" }}>{((i % data.photos.length) + 1)}/{data.photos.length}</span>} />
            </div>)
          })
          }
        </ReactSwipe>
      </Grid>

      {(!minimalView) && (<>
        <Grid item xs={12} style={{ position: "relative" }} >
          <CustomNameLabel gutterBottom variant="h6" component="div" style={{
            position: "absolute",
            zIndex: "400",
            bottom: "50px",
            left: "30px",
          }} >
            {data.guideName}
          </CustomNameLabel>
        </Grid>
        <Box sx={{ width: "100%", flexGrow: 1, marginTop: "-50px", padding: "0 " }}>
          <Grid container>
            <Grid item xs={6} style={{ textAlign: "left" }} >
              <Button size="small" aria-label="Help" onClick={handleBack} >
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
              </Button>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }} >
              <Button size="small" color="primary" aria-label="Help" onClick={handleNext}>
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </>
      )}
    </>)
}

export default GuideImages;


