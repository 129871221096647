import { Dialog, Button, AppBar, Divider, IconButton, List, ListItemButton, ListItemText, Slide, Toolbar, Typography, Box, TextField, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { appContext } from "../App";
import { LoginInfo } from "../helpers/AuthHelper";
import { TransitionProps } from "@mui/material/transitions";
import { CloseIcon } from "yet-another-react-lightbox";
import MuiPhoneNumber from 'mui-phone-number';
import { ConfirmationResult, RecaptchaVerifier, getAuth, signInWithPhoneNumber } from "firebase/auth";
import CircularProgress from '@mui/material/CircularProgress';
import { blob } from "stream/consumers";


export default function AuthLogin() {
    const auth = getAuth();


    const [otpGenerated, setGenerateOtp] = useState<boolean>(false);
    const [submitOtp, setSubmitOtp] = useState<boolean>(false);


    const [number, setNumber] = useState<string>("");
    const [confObj, setConfObj] = useState<ConfirmationResult | null>(null);
    const [otp, setOtp] = useState<string>("");



    const { setAuthDoLogin, authDoLogin, setAuthLoginInfo } = React.useContext<{
        setAuthDoLogin: React.Dispatch<React.SetStateAction<boolean>>,
        authDoLogin: boolean,
        setAuthLoginInfo: React.Dispatch<React.SetStateAction<LoginInfo | null>>,
    }>(appContext);

    function handleClose() {
        setAuthDoLogin(false)
    }

    const Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
            children: React.ReactElement;
        },
        ref: React.Ref<unknown>,
    ) {
        return <Slide direction="up" ref={ref} {...props} />;
    });


    function getOtp() {
        if (number == "") return;
        var recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {});
        recaptchaVerifier.render()
        signInWithPhoneNumber(auth, number, recaptchaVerifier)
            .then(o => {
                setConfObj(o)
                setGenerateOtp(true)
            })
            .catch(e => {
                console.log(e);
            })

    }

    function validateOtp() {
        setSubmitOtp(true)
        if (confObj != null) {
            confObj.confirm(otp).then(r => {
                handleClose();
                resetState();
            }).catch(e => {
                console.log(e)
                setSubmitOtp(false)
            })
        }
    }
    function resetState() {
        setGenerateOtp(false);
        setSubmitOtp(false);
        setNumber("");
        setConfObj(null);
        setOtp("");
    }
    useEffect(() => {
        resetState();
    }, [])
    useEffect(() => {
        resetState();
    }, [authDoLogin])
    return <>

        <Dialog
            fullScreen
            open={authDoLogin}
            onClose={handleClose}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Sign in using phone
                    </Typography>
                </Toolbar>
            </AppBar>
            <Grid container spacing={2} padding={5} >
                <Grid item xs={12} >
                    {(submitOtp) && (
                        <Box sx={{ display: 'flex', position: "fixed", zIndex: 1000, background: "rgba(255,255,255,0.5)", width: "100vw", height: "100vh" }}>
                            <div style={{ display: "block", margin: "auto" }}>
                                <CircularProgress />
                            </div>
                        </Box>
                    )}
                </Grid>
                <Grid item xs={12}  >
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={10} md={10} >
                            <MuiPhoneNumber defaultCountry={'in'} onChange={(val) => { setNumber(val + "") }} value={number} fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={2} md={2}>
                            <Button variant="contained" size="medium" onClick={getOtp} fullWidth>
                                Get otp
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} ><div id="recaptcha-container"></div></Grid>
                <Grid item xs={12} >
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={10} md={10} >
                            <TextField id="standard-basic" disabled={(!otpGenerated)} size="small" label="OTP" variant="standard" value={otp} onChange={n => { setOtp(n.target.value) }} fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={2} md={2}>
                            <Button variant="contained" disabled={(!(otpGenerated || submitOtp))} size="medium" onClick={validateOtp} fullWidth>
                                Validate
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    </>
} 