
import { Container, Typography, Grid, Button, Snackbar, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { API_PATH, appContext } from '../App';
import { useNavigate } from 'react-router-dom';


export function getLanguages(success: (x: string[]) => void) {
    axios.get(API_PATH + '/travel/search/languages')
        .then(function (response) {
            success(response.data)
        })
        .catch(function (error) {
            console.log(error);
        })
}

const SearchPage: React.FC = () => {
    const { languageList } = useContext<{ languageList: string[] }>(appContext);

    const [searchLanguage, setSearchLanguage] = useState<string[]>([]);
    const [searchDate, setSearchDate] = useState('');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const navigate = useNavigate();

    function submitPage() {
        if (searchDate == '') {
            setErrorMessage("Invalid Date")
            return;
        }
        if (searchLanguage.length == 0) {
            setErrorMessage("Invalid Language")
            return;
        }
        navigate('/search?date=' + searchDate + '&language=' + searchLanguage.join(','));
    }

    useEffect(() => {
    }, [])
    const handleChange = (event: SelectChangeEvent<typeof searchLanguage>) => {
        const {
            target: { value },
        } = event;
        setSearchLanguage(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    return (
        <Container>
            <Grid container spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ height: "100vh" }}
            >
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h4" gutterBottom>
                                Search Page
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth size='small'>
                                <InputLabel id="demo-simple-select-label">Language</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={searchLanguage}
                                    multiple
                                    label="Language"
                                    onChange={handleChange}
                                >
                                    {languageList.map((n, i) => { return <MenuItem value={n} key={i} >{n}</MenuItem> })}

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={8}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    disablePast
                                    onChange={(x) => { setSearchDate((x?.format("DD/MM/YYYY")) ?? "") }}
                                    slotProps={{ textField: { size: 'small' } }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <Button variant="contained" size='large' onClick={submitPage} >Search</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Snackbar
                open={errorMessage != null}
                autoHideDuration={6000}
                onClose={() => { setErrorMessage(null) }}
                message={errorMessage}
            />
        </Container>
    );
};


export default SearchPage;