import { Box, Button, CssBaseline, IconButton, Menu, MenuItem, Toolbar, Typography } from "@mui/material"
import { useContext, useEffect, useState } from "react";
import { CustomAppBar } from "../helpers/CustomStyles";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MoreIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import { appContext } from "../App";
import { AppPlatform } from "../helpers/PlatformHelper";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { LoginInfo } from "../helpers/AuthHelper";
import PersonIcon from '@mui/icons-material/Person';

interface AppMenuProps {
    fullWidth?: boolean
    isHome?: boolean
}


const AppMenu: React.FC<AppMenuProps> = ({ fullWidth, isHome }) => {

    const navigate = useNavigate();

    const { authLoginInfo, platformDetected, setAuthDoLogin } = useContext<{
        authLoginInfo: LoginInfo | null,
        platformDetected: AppPlatform,
        setAuthDoLogin: React.Dispatch<React.SetStateAction<boolean>>,
    }>(appContext);


    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);
    const [menuItems, setMenuItems] = useState<{
        label: string;
        action: (event: React.MouseEvent<HTMLElement>) => void;
        icon: JSX.Element;
    }[]>([]);

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleFaqMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(null);
        navigate('/faq');

    };
    const handleAboutMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(null);
        navigate('/about');

    };
    const handleMyAccount = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(null);
        navigate('/myAccount');
    };
    const handleAuthLogin = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(null);
        setAuthDoLogin(true);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };


    useEffect(() => {
        if (authLoginInfo == null) {
            setMenuItems([
                { label: "About", action: handleAboutMenuOpen, icon: <InfoIcon /> },
                { label: "FAQ", action: handleFaqMenuOpen, icon: <HelpOutlineIcon /> },
                { label: "Login", action: handleAuthLogin, icon: <PersonIcon /> }
            ])
        } else {
            setMenuItems([
                { label: "About", action: handleAboutMenuOpen, icon: <InfoIcon /> },
                { label: "FAQ", action: handleFaqMenuOpen, icon: <HelpOutlineIcon /> },
                { label: "My Account", action: handleMyAccount, icon: <PersonIcon /> }
            ])
            console.log(authLoginInfo)
        }
    }, [authLoginInfo])



    const renderMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            {menuItems.map((page) => (
                <MenuItem onClick={page.action} key={page.label}>
                    <IconButton
                        size="small"
                        aria-label="About"
                        color="inherit"
                    >
                        {page.icon}
                    </IconButton>
                    <span>{page.label}</span>
                </MenuItem>
            ))}
        </Menu>
    );

    return <>

        <CssBaseline />
        <CustomAppBar >
            <Toolbar>
                <Box sx={{ flexGrow: 1, }} >
                    {(isHome || platformDetected == AppPlatform.Unknown) ?
                        (<Typography variant="h6" component="div" onClick={() => { navigate("/") }} style={{ cursor: "pointer" }}> Deep Tour </Typography>) :
                        (<ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />)}
                </Box>
                <Box sx={{ display: { xs: "none", sm: "none", md: "flex" } }} >
                    {menuItems.map((page) => (
                        <Button
                            key={page.label}
                            onClick={page.action}
                            sx={{ my: 2, color: 'black', display: 'block' }}
                        >
                            {page.label}
                        </Button>
                    ))}
                </Box>
                <Box sx={{ display: { xs: 'flex', md: "none" } }}>
                    <IconButton
                        size="large"
                        aria-label="show more"
                        aria-controls={mobileMenuId}
                        aria-haspopup="true"
                        onClick={handleMobileMenuOpen}
                        color="inherit"
                    >
                        <MoreIcon />
                    </IconButton>
                    {renderMenu}
                </Box>
            </Toolbar>
        </CustomAppBar >
        <Toolbar id="back-to-top-anchor" />
    </>
}

export default AppMenu