import { Box, Button, Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, List, ListItem, ListItemText, Stack, Typography } from "@mui/material";
import AppMenu from "./AppMenu";
import { useContext, useEffect, useState } from "react";
import { API_PATH, appContext } from "../App";
import { LoginInfo } from "../helpers/AuthHelper";
import { getAuth, signOut } from "firebase/auth";
import Loading from "./Loading";
import { useNavigate } from "react-router-dom";
import axios from "axios";


function MyAccount() {
  const { authLoginInfo } = useContext<{ authLoginInfo: LoginInfo | null }>(appContext);
  const auth = getAuth();
  const navigate = useNavigate();
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
  const [orderInfo, setOrderInfo] = useState<any[]>([]);
  function doLogout() {
    signOut(auth).then(() => {
      navigate('/');
    }).catch((error) => {

    });
  }
  function doDeleteDialogOpen() {
    setDeleteDialog(true)
  }
  function doDeleteDialogeClose() {
    setDeleteDialog(false)
  }
  function doDelete() {
    auth.currentUser?.delete()
      .then(() => {
        doDeleteDialogeClose()
        navigate('/');
      })
      .catch((e) => { console.log(e) })
  }
  function getOrderIndo() {
    if (authLoginInfo == null) {
      return;
    }
    axios.get(API_PATH + '/user/purchase/list?uid=' + authLoginInfo.data.uid)
      .then(function (response) {
        setOrderInfo(response.data)
        console.log(response.data)
      })
      .catch(function (error) {
        console.log(error);
      })
  }
  useEffect(() => {
    getOrderIndo()
  }, [authLoginInfo])

  return (
    <Grid container  >
      <AppMenu />
      {(authLoginInfo == null) && (<Loading />)}
      {(authLoginInfo != null) && (
        <Grid item xs={12}>
          <Grid container padding={5}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography variant="h5" gutterBottom>
                Account : {authLoginInfo?.data.phoneNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} paddingTop={2}>
              <Grid container >
                <Grid item xs={12} sm={3}>
                  <p>&nbsp;</p>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography gutterBottom variant="h5" component="div">
                    My bookings
                  </Typography>
                  {(orderInfo.length == 0) && (<p>No booking....</p>)}
                  {orderInfo.map((n) => (
                    <Card variant="outlined" sx={{ marginBottom: 3 }}>
                      <Box sx={{ p: 2 }}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                          <Typography gutterBottom variant="h6" component="div">
                            Order #{n.oid}
                          </Typography>
                          <Typography gutterBottom variant="body2" component="div">
                            Booked Date  : {n.bookDate}
                          </Typography>
                        </Stack>
                        <Typography color="text.secondary" variant="body2">
                          Guide  : {n.guide.guideName}
                        </Typography>
                      </Box>
                    </Card>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} paddingTop={5}>
              <Grid container style={{ textAlign: "center" }} spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Button variant="outlined" color="secondary" onClick={doLogout}> Logout </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button variant="outlined" color="error" onClick={doDeleteDialogOpen}> Delete Account</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Dialog
            open={deleteDialog}
            onClose={doDeleteDialogeClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Warning: This action is irreversible!
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete your account? Deleting your account will result permanent Loss of Data.<br />
                Access Revocation: You will no longer be able to access your account or any of its features.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={doDeleteDialogeClose}>Cancel</Button>
              <Button onClick={doDelete} autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      )}
    </Grid>)
}



export default MyAccount;