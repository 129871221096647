import { CircularProgress, Stack } from "@mui/material";
import { useState } from "react";
import Warning from '@mui/icons-material/Warning';


interface ImgProps {
    src: string,
    onClick: () => void,
    maxHeight?: boolean,
    extra?: JSX.Element
}

const ImageLoader: React.FC<ImgProps> = ({ src, onClick, maxHeight, extra }) => {
    const [imgLoaded, setImgLoaded] = useState<boolean>(false);
    const [imgError, setImgError] = useState<boolean>(false);
    return (<>
        {(extra) && (<>{extra}</>)}
        <div className="galleryImgDiv" style={imgLoaded ? { backgroundImage: `url(${src})` } : {}} onClick={onClick} >
            {(!imgLoaded && !imgError) && (<CircularProgress color="primary" />)}
            {(imgError) && (<Stack direction="column" spacing={2}>
                <Warning color="error" style={{ margin: "10px auto" }} />
                <span style={{ color: "#fff", textShadow: "0px 0px 2px #000000" }}>Error Loading Image.</span>
            </Stack>)}

            <img src={src}
                style={{ width: "1px" }}
                onLoad={() => { setImgLoaded(true) }}
                onError={() => { setImgError(true) }}
            />
        </div>
    </>)
}

export default ImageLoader