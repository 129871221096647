import * as React from 'react';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import { Button, Dialog, DialogContent, DialogTitle, Fab, Grid, Stack, Typography } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import { appContext } from '../App';
import { Guide } from '../pages/SearchReasult';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { AppPlatform } from '../helpers/PlatformHelper';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { LoginInfo } from '../helpers/AuthHelper';


const Root = styled('div')(({ theme }) => ({
    height: '100%',
    backgroundColor:
        theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled('div')(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}));


export function ConnectApps(props: any) {

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [active, setActive] = useState<boolean>(false);

    const { setBookGuide, bookGuide, bookDate, platformDetected, iOSPurchaseAmount, setAuthDoLogin, authLoginInfo, setLoader2 } = React.useContext<{
        setBookGuide: React.Dispatch<React.SetStateAction<Guide | null>>,
        bookGuide: Guide | null,
        bookDate: string,
        platformDetected: AppPlatform,
        iOSPurchaseAmount: string,
        setAuthDoLogin: React.Dispatch<React.SetStateAction<boolean>>,
        authLoginInfo: LoginInfo | null,
        setLoader2: React.Dispatch<React.SetStateAction<boolean>>,
    }>(appContext);


    const toggleDrawer = (newOpen: boolean) => () => {
        if (!newOpen) {
            setBookGuide(null);
            setActive(false)
        }
    };



    const [drawerBleeding, setdrawerBleeding] = useState<number>(90);

    useEffect(() => {
        if (iOSPurchaseAmount != null) {
            setdrawerBleeding(90)
        }
    }, [iOSPurchaseAmount])

    useEffect(() => {
        setActive(bookGuide != null)
    }, [bookGuide])

    function showLoader() {
        setTimeout(() => {
            setBookGuide(null)
            setLoader2(true)
            setTimeout(() => {
                setLoader2(false)
            }, 60000);
        }, 500);
    }

    function informBooking(appName: string) {
        /** / 
        if (authLoginInfo == null) {
            setAuthDoLogin(true)
            return;
        }
        /**/
        console.log("Platform detected :" + platformDetected + " App selected : " + appName)
        let shareText = "Book " + bookGuide?.guideName + "( " + bookGuide?.id + " )" + " on " + bookDate;
        if (platformDetected == AppPlatform.Android) {
            if ((window) && ((window as any).android)) {
                /** /
                if (authLoginInfo == null) {
                    setAuthDoLogin(true)
                    return;
                }
                shareText = JSON.stringify({ uid: authLoginInfo.uid, bookGuide: bookGuide!.id, bookDate: bookDate });
                /**/
                (window as any).android.onBookNowPressed(appName, shareText);
                showLoader();
                return;
            } else {
                console.log("Android function call missed")
            }
        }

        if (platformDetected == AppPlatform.iOS) {
            if ((window) && ((window as any).webkit)) {
                if (authLoginInfo == null) {
                    setAuthDoLogin(true)
                    return;
                }
                shareText = JSON.stringify({ uid: authLoginInfo.uid, bookGuide: bookGuide!.id, bookDate: bookDate });
                (window as any).webkit.messageHandlers.onBookNowPressed.postMessage({ bookOn: appName, message: shareText });
                showLoader();
                return;
            } else {
                console.log("ios function call missed")
            }
        }
        // website
        switch (appName) {
            case "WhatsApp":
                window?.open(('https://wa.me/+66818279315?text=' + encodeURI(shareText)), '_blank')?.focus();
                break;
            case "Telegram":
                window?.open('https://telegram.me/share/url?url=DeeptourPhuket&text=' + encodeURI(shareText), '_blank')?.focus()
                break;
            case "Phone":
                window?.open('tel:+66818279315', '_blank')?.focus();
                break;
        }

        setTimeout(() => { setBookGuide(null) }, 500);

    }

    return (
        <Root>
            <CssBaseline />
            <Global
                styles={{
                    '.MuiDrawer-root > .MuiPaper-root': {
                        height: `calc(25% - ${drawerBleeding}px)`,
                        overflow: 'visible',
                    },
                }}
            />
            <Dialog
                open={active && !loading}
                onClose={toggleDrawer(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Contact us for booking"}
                </DialogTitle>
                <DialogContent>
                    <Grid container height="110%" spacing={2} alignContent={"center"} textAlign={"center"} >
                        <Grid item xs={12}>

                            <Typography variant="h6" color="text.secondary">
                                {bookGuide?.guideName} for {bookDate}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} >
                            <Stack direction="row" spacing={2}
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Fab size="medium" color="success" aria-label="Close" onClick={() => { informBooking("WhatsApp") }}>
                                    <WhatsAppIcon />
                                </Fab>
                                <Fab size="medium" color="primary" aria-label="Close" onClick={() => { informBooking("Telegram") }}>
                                    <TelegramIcon />
                                </Fab>
                                <Fab size="medium" color="secondary" aria-label="Close" onClick={() => { informBooking("Phone") }}>
                                    <LocalPhoneIcon />
                                </Fab>

                            </Stack>
                        </Grid>
                        {(iOSPurchaseAmount.length > 0) && (<>
                            <Grid item xs={12} >
                                <Button variant="outlined" size="small" onClick={() => { informBooking("Online") }}>  Book slot now at {iOSPurchaseAmount!} </Button>
                            </Grid>
                        </>)}
                    </Grid>
                </DialogContent>
            </Dialog>

        </Root>
    );
}

export default ConnectApps