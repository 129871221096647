
import { Routes, Route } from 'react-router-dom';
import SearchReasult, { Guide } from './pages/SearchReasult';
import React, { useEffect, useState } from 'react';
import { getLanguages } from './pages/SearchPage';
import GuideView from './pages/GuideView';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Loading from './subPages/Loading';
import ConnectApps from './subPages/ConnectApps';
import { AppPlatform, detectPlatform } from './helpers/PlatformHelper';
import "./App.css"
import "yet-another-react-lightbox/styles.css";
import FaqPage from './pages/FaqPage';
import AboutPage from './pages/AboutPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CommunicateWithMobileApp from './subPages/CommunicateWithMobileApp';
import AuthHelper, { LoginInfo } from './helpers/AuthHelper';
import Messages, { MessageBody } from './subPages/Messages';
import MyAccount from './subPages/MyAccount';

export const appContext = React.createContext<any | null>(null);

export const API_PATH = "/adpumb"

function App() {
  const [languageList, setLanguageList] = useState<string[]>([]);
  const [bookGuide, setBookGuide] = useState<Guide | null>(null);
  const [bookDate, setBookDate] = useState<string>('');
  const [platformDetected, setPlatformDetected] = useState(AppPlatform.Unknown);
  const [isLoading, setIsLoading] = useState(true);
  const [searchResults, setSearchResults] = useState<Guide[] | null>(null);
  const [iOSPurchaseAmount, setiOSPurchaseAmount] = useState<string>('');
  const [authLoginInfo, setAuthLoginInfo] = useState<LoginInfo | null>(null);
  const [authDoLogin, setAuthDoLogin] = useState<boolean>(false);
  const [loader2, setLoader2] = useState(false);
  const [message, setMessage] = useState<MessageBody | null>(null);
  useEffect(() => {
    detectPlatform(setPlatformDetected)
    setIsLoading(true)
    getLanguages((x) => {
      setLanguageList(x)
      setIsLoading(false)
    })
  }, [])
  return (
    <appContext.Provider value={
      {
        languageList: languageList,
        bookGuide: bookGuide,
        setBookGuide: setBookGuide,
        bookDate: bookDate,
        setBookDate: setBookDate,
        setPlatformDetected: setPlatformDetected,
        platformDetected: platformDetected,
        searchResults: searchResults,
        setSearchResults: setSearchResults,
        iOSPurchaseAmount: iOSPurchaseAmount,
        setiOSPurchaseAmount: setiOSPurchaseAmount,
        authLoginInfo: authLoginInfo,
        setAuthLoginInfo: setAuthLoginInfo,
        authDoLogin: authDoLogin,
        setAuthDoLogin: setAuthDoLogin,
        loader2: loader2,
        setLoader2: setLoader2,
        message: message,
        setMessage: setMessage
      }}>
      {(isLoading) && (<Loading />)}
      <Routes>
        <Route path="/" element={<SearchReasult />} />
        <Route path="/search" element={<SearchReasult />} />
        <Route path="/guide" element={<GuideView />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/myAccount" element={<MyAccount />} />
        <Route path="*" element={<SearchReasult />} />
      </Routes>
      {/* <FloatingIcon active={!isLoading} /> */}
      <ConnectApps />
      <CommunicateWithMobileApp />
      <AuthHelper />
      {(loader2) && (<Loading />)}
      {(message) && (<Messages />)}
    </appContext.Provider>
  );
}

export default App;
