import { Grid, Stack, Typography } from '@mui/material';

export default function WelcomeImage() {
    return (
        <Grid container  >
            <Grid item xs={12} sx={{ minHeight: { xs: "50vh", sm: "80vh" }, backgroundImage: "url(/website/bg.webp)" }} className="landing-img-grid"  >
                <Stack direction="column" spacing={2} height={"100%"} width={"100%"} justifyContent="flex-end"  >

                    <Typography variant="h3" gutterBottom sx={{ fontSize: { xs: "30px", sm: "60px" }, color: "#fff", maxWidth: "700px", lineHeight: "50px" }} style={{ margin: "20px auto" }} > Your Portal to Personalized Adventures</Typography>
                </Stack>
            </Grid>
            <Grid container>
                <Grid item xs={12} sm={4} className='welcome-tile' style={{ backgroundColor: "rgb(200, 72, 105)" }}>
                    <Typography variant="h3" gutterBottom>
                        Our Mission
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        Our Goal, Vision & Commitment
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} className='welcome-tile' style={{ backgroundColor: "rgb(42, 112, 222)" }}>
                    <Typography variant="h3" gutterBottom>
                        Our Events
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        Register & Help Make Change
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} className='welcome-tile' style={{ backgroundColor: "rgb(33, 62, 140)" }}>
                    <Typography variant="h3" gutterBottom>
                        Get Involved
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        Volunteer, Participate, or Donate
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}