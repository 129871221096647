import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AppMenu from '../subPages/AppMenu';

const faqData = [
  {
    title: "General Questions:",
    qna: [
      {
        question: 'What is Deep Tour?',
        answer: 'Deep Tour is an online platform dedicated to travel and tourism, offering comprehensive information, guides, and booking services for travelers worldwide.'
      },
      {
        question: 'How can Deep Tour help me plan my trip?',
        answer: 'Deep Tour provides destination guides, travel itineraries, booking options for flights and accommodations, and insightful travel tips to assist you in planning and enjoying your trip.',
      },
      {
        question: 'Is Deep Tour free to use?',
        answer: 'Yes, browsing and accessing information on Deep Tour is free. Booking services may involve fees depending on the service provider.',
      },
    ]
  },
  {
    title: "Booking and Reservations:",
    qna: [
      {
        question: 'Can I book flights and accommodations through Deep Tour?',
        answer: 'Yes, Deep Tour offers a convenient booking platform where you can search, compare, and book flights, hotels, and other accommodations.',
      },
      {
        question: 'Are there additional fees for using Deep Tour\'s booking services?',
        answer: 'Deep Tour may charge service fees or booking fees depending on the service and provider. Any fees will be clearly indicated during the booking process.',
      },
      {
        question: 'How do I cancel or modify a booking made through Deep Tour?',
        answer: 'For cancellations or modifications, please refer to the specific booking details provided at the time of reservation. Policies vary depending on the service provider.',
      },
    ]
  },
  {
    title: "Travel Guides and Itineraries:",
    qna: [
      {
        question: 'Does Deep Tour provide travel guides for specific destinations?',
        answer: 'Yes, Deep Tour offers detailed destination guides that include information on attractions, activities, local culture, dining options, and more.',
      },
      {
        question: 'Can I find travel itineraries on Deep Tour?',
        answer: 'Absolutely! Deep Tour offers sample travel itineraries tailored to different interests and durations, helping you plan your trip efficiently.',
      },
    ]
  },
  {
    title: "Customer Support:",
    qna: [
      {
        question: 'How can I contact Deep Tour\'s customer support?',
        answer: 'You can reach Deep Tour\'s customer support team through the contact form on our website.We strive to respond to inquiries promptly.',
      },
      {
        question: 'Is there support available for emergencies during my trip?',
        answer: 'While Deep Tour provides travel information and booking services, for emergencies during your trip, please contact local authorities or emergency services as necessary.',
      },
    ]
  },
  {
    title: "Privacy and Security:",
    qna: [
      {
        question: 'Is my personal information secure with Deep Tour?',
        answer: 'Deep Tour values your privacy and employs industry-standard security measures to protect your personal information. Please refer to our Privacy Policy for more details.',
      },
      {
        question: 'Does Deep Tour share my information with third parties?',
        answer: 'Deep Tour respects your privacy and does not sell or rent your personal information to third parties. We may share information with service providers as necessary to fulfill your bookings.',
      },
    ]
  }
];

function FaqPage() {
  const navigate = useNavigate();

  return (
    <Grid container  >
      <AppMenu />
      <Grid item xs={12}>
        <Grid container padding={"40px 20px"} style={{ maxWidth: "1000px", margin: "0px auto" }}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Frequently Asked Questions (FAQ) - Deep Tour
            </Typography>
          </Grid>
          {faqData.map((faqGroup, index) => (
            <Grid item key={index} spacing={2} >
              <Grid item xs={12}> <Typography variant="h6" gutterBottom>{faqGroup.title}</Typography></Grid>
              {faqGroup.qna.map((faq, j) => (<Grid item xs={12} style={{ marginBottom: "20px" }}>
                <Accordion key={j} defaultExpanded >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel-${index}-content`}
                    id={`panel-${index}-header`}
                  >
                    <Typography variant="body1"  >
                      {faq.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant='body2'>
                      {faq.answer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>))}

            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default FaqPage;


