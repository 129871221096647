import { Button, CardMedia, Skeleton, ThemeProvider, createTheme } from "@mui/material"
import { MouseEvent, useContext, useState } from "react";
import { Guide } from "../pages/SearchReasult";
import { appContext } from "../App";
import { BookNowButton } from "../helpers/CustomStyles";

interface ButtonProps {
    data: Guide;
    bookingDate: string;
    fullWidth?: boolean
}


const BookNow: React.FC<ButtonProps> = ({ data, bookingDate, fullWidth }) => {

    const { setBookGuide, setBookDate } = useContext<{ setBookGuide: React.Dispatch<React.SetStateAction<Guide | null>>, setBookDate: React.Dispatch<React.SetStateAction<string>> }>(appContext);

    function bookGuide(e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) {
        e.stopPropagation();
        setBookGuide(data);
        setBookDate(bookingDate)
    }

    return <>
        <BookNowButton variant="contained" color="primary" size="small" fullWidth={fullWidth} onClick={(e) => { bookGuide(e) }}>
            Book Now
        </BookNowButton>
    </>
}

export default BookNow