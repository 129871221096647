import * as React from 'react';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { User, getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect } from 'react';
import axios from "axios";
import { API_PATH, appContext } from '../App';
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from '@mui/material';
import AuthLogin from '../subPages/AuthLogin';

const firebaseConfig = {
    apiKey: "AIzaSyCMcXEB6SaRYcbqYslzmFh0yoL53Q22Uj4",
    authDomain: "travel-guide-info.firebaseapp.com",
    projectId: "travel-guide-info",
    storageBucket: "travel-guide-info.appspot.com",
    messagingSenderId: "874793794484",
    appId: "1:874793794484:web:65fdce27b5737eede750e1",
    measurementId: "G-8QQ2SK07N7"
};

export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAnalytics = getAnalytics(firebaseApp);
export const auth = getAuth(firebaseApp);

export interface LoginInfo {
    data: User;
    uid: string;
    isLogin: boolean
}

export default function AuthHelper() {

    const { setAuthLoginInfo, authLoginInfo, setAuthDoLogin, authDoLogin, setLoader2 } = React.useContext<{
        setAuthLoginInfo: React.Dispatch<React.SetStateAction<LoginInfo | null>>,
        authLoginInfo: LoginInfo | null,
        setAuthDoLogin: React.Dispatch<React.SetStateAction<boolean>>,
        authDoLogin: boolean,
        setLoader2: React.Dispatch<React.SetStateAction<boolean>>,
    }>(appContext);

    useEffect(() => {
        getAuthInfo()
    }, [])
    function getAuthInfo() {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setAuthLoginInfo({ data: user, isLogin: true, uid: user.uid })
            } else {
                setAuthLoginInfo(null)
                setLoader2(false)
            }
        })
        axios.get(API_PATH + '/travel/auth/')
            .then(function (response) {
                console.log(response)
            })
            .catch(function (error) {
                console.log(error);
            })
    }
    return (<>
        {(authLoginInfo == null) && (<AuthLogin />)}
    </>);
}