
export enum AppPlatform {
    Android = "Android",
    iOS = "iOS",
    Unknown = "Unknown"
}

export function detectPlatform(callback: (platforn: AppPlatform) => void) {
    var userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
        return callback(AppPlatform.Android)
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return callback(AppPlatform.iOS);
    }

}