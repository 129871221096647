import * as React from 'react';
import { API_PATH, appContext } from '../App';
import { useEffect } from 'react';
import axios from 'axios';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { MessageBody } from './Messages';
import { useNavigate } from 'react-router-dom';
import { AppPlatform } from '../helpers/PlatformHelper';
import { Guide } from '../pages/SearchReasult';

export function CommunicateWithMobileApp(props: any) {

    const { setiOSPurchaseAmount, setLoader2, setIsLoading, setMessage, setPlatformDetected, setSearchResults } = React.useContext<{
        setiOSPurchaseAmount: React.Dispatch<React.SetStateAction<String | null>>,
        setLoader2: React.Dispatch<React.SetStateAction<boolean>>,
        setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
        setMessage: React.Dispatch<React.SetStateAction<MessageBody | null>>,
        setPlatformDetected: React.Dispatch<React.SetStateAction<AppPlatform>>,
        setSearchResults: React.Dispatch<React.SetStateAction<Guide[] | null>>
    }>(appContext);

    const navigate = useNavigate();

    function handleCleanLoader() {
        setLoader2(false)
        setIsLoading(false)
    }

    function handleiOSPurchaseAmount(message: string) {
        console.log(message)
        setiOSPurchaseAmount(message);
        setPlatformDetected(AppPlatform.iOS);
    }

    function handleiOSTransactionComplete(data: any) {
        setLoader2(true)
        console.log(data)
        setSearchResults(null)
        axios.post(API_PATH + '/user/purchase', data)
            .then(response => {
                console.log("axios response");
                console.log(response.data);
                setMessage({
                    isError: false,
                    title: "Booking completed",
                    message: <span>{"Booking completed id: " + response.data.oid + " for: " + response.data.bookDate}</span>
                })
                navigate('/myAccount');
                console.log("Actions completed");
                handleCleanLoader();
            })
            .catch(error => {
                console.log(error);
                handleCleanLoader()
            });
    }
    function handleErrorMessage(title: string, message: string) {
        console.log(title + " : " + message)
        handleCleanLoader();
        setMessage({
            isError: true,
            title: title,
            message: <span>{message}</span>
        })
    }

    function handleSetPlatform(platform: string) {
        if (platform == "ios") {
            setPlatformDetected(AppPlatform.iOS);
        }
        if (platform == "android") {
            setPlatformDetected(AppPlatform.Android);
        }
    }

    useEffect(() => {
        // @ts-ignore
        window.handleiOSPurchaseAmount = handleiOSPurchaseAmount;
        // @ts-ignore
        window.handleiOSTransactionComplete = handleiOSTransactionComplete;
        // @ts-ignore
        window.handleCleanLoader = handleCleanLoader;
        // @ts-ignore
        window.handleErrorMessage = handleErrorMessage;
        // @ts-ignore
        window.handleSetPlatform = handleSetPlatform;
        return () => {
            // @ts-ignore
            window.handleiOSPurchaseAmount = null;
            // @ts-ignore
            window.handleiOSTransactionComplete = null;
            // @ts-ignore
            window.handleCleanLoader = null;
            // @ts-ignore
            window.handleErrorMessage = null;
            // @ts-ignore
            window.handleSetPlatform = null;
        };
    }, [])


    return (
        <></>
    );
}

export default CommunicateWithMobileApp