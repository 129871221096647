import { Typography, Grid, Box, List, ListItem, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AppMenu from '../subPages/AppMenu';


function PrivacyPolicy() {
  const navigate = useNavigate();

  return (
    <Grid container  >
      <AppMenu />
      <Grid item xs={12}>
        <Grid container padding={"40px 20px"} style={{ maxWidth: "1000px", margin: "0px auto" }}>
          <Grid item xs={12}>
            <Typography variant="body2" gutterBottom>
              <b>Privacy Policy for Deep Tour</b>
            </Typography>
            <Typography variant="body2" gutterBottom>
              <br />
              1. Introduction <br />

              Welcome to Deep Tour. Your privacy is important to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website [deep-tour.com] or use our services.

            </Typography>
            <Typography variant="body2" gutterBottom>
              2. Information We Collect <br />

              We may not collect any personal information  when using our services
            </Typography>
            <Typography variant="body2" gutterBottom>
              3. Use of Information <br />

              We use the information we collect for various purposes, including:

              <ul>
                <li>Providing and improving our services</li>
                <li>Personalizing your experience</li>
                <li>Processing transactions</li>
                <li>Communicating with you</li>
                <li>Analyzing trends and user behavior</li>
                <li>Marketing and promotional purposes (with your consent where required by law) </li>
              </ul>
            </Typography>
            <Typography variant="body2" gutterBottom>
              4. Disclosure of Information <br />

              We may share your information with third parties in the following circumstances:

              Service providers and partners who assist us in operating our website and providing services
              Legal obligations, such as to comply with applicable laws or regulations
              Business transfers, in connection with a merger, acquisition, or sale of assets

            </Typography>
            <Typography variant="body2" gutterBottom>
              5. Security of Information <br />

              We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is completely secure. Therefore, we cannot guarantee absolute security.

            </Typography>
            <Typography variant="body2" gutterBottom>
              6. Your Choices and Rights <br />

              You have certain rights regarding your personal information, including the right to access, correct, or delete your information. You may also choose to opt-out of certain communications or withdraw consent for processing where applicable.

            </Typography>
            <Typography variant="body2" gutterBottom>
              7. Cookies and Tracking Technologies <br />

              We use cookies and similar tracking technologies to enhance your experience on our website. You can manage your cookie preferences through your browser settings.

            </Typography>
            <Typography variant="body2" gutterBottom>
              8. Children's Privacy <br />

              Our services are not directed to children under the age of 13. We do not knowingly collect personal information from children. If we learn that we have inadvertently collected information from a child, we will take steps to delete it.

            </Typography>
            <Typography variant="body2" gutterBottom>
              9. Changes to This Privacy Policy <br />

              We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on our website. You are advised to review this Privacy Policy periodically for any changes.

            </Typography>
            <Typography variant="body2" gutterBottom>
              10. Contact Us <br />

              If you have any questions about this Privacy Policy or our practices, please contact us at [contact@deep-tour.com].

            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PrivacyPolicy;


