import { Box, CircularProgress } from "@mui/material";
import { abort } from "process";

function Loading() {
  return (<div style={{ position: "fixed", top: 0, left: 0 }}><Box display="flex" justifyContent="center" alignItems="center" minHeight={"100vh"} width={"100vw"}
    sx={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, background: "rgba(255, 255, 255,0.7)", zIndex: 1000 }} >
    <CircularProgress />
  </Box></div>)
}

export default Loading;