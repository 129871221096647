import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, Grid, Skeleton, Stack, ThemeProvider, Typography, createTheme } from "@mui/material";
import { Guide } from "../pages/SearchReasult";
import { useNavigate } from 'react-router-dom';
import BookNow from "./BookNow";
import GuideImages from "./GuideImages";
import { useEffect, useRef, useState } from "react";
import GuideInfo from "./GuideInfo";
import WcIcon from '@mui/icons-material/Wc';
import ManIcon from '@mui/icons-material/Man';
import WomanIcon from '@mui/icons-material/Woman';


function getSkeleton(): React.ReactNode {
  return <>
    <Skeleton variant="rectangular" height={118} />
    <Box sx={{ pt: 0.5 }}>
      <Skeleton />
      <Skeleton width="60%" />
    </Box></>
}

function ResultView(props: { results: Guide[] | null[], date: string }) {
  const { results, date } = props
  const navigate = useNavigate();

  const [selectedGuide, setSelectedGuide] = useState<Guide | null>(null);
  const [scroll, setScroll] = useState<DialogProps['scroll']>('body');
  const descriptionElementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (selectedGuide != null) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [selectedGuide])

  function getGuideInfo(n: Guide) {
    navigate('/guide?id=' + n.id + '&date=' + date);
    //  setSelectedGuide(n)
  }

  return (<Grid container spacing={1} style={{ paddingTop: "20px" }}>
    {results.map((n, i) => {
      if (n != null && n.hide) {
        return <></>
      }
      let description: string = (n == null) ? "" : n.shortDescription ?? n.description;
      return (
        <Grid item xs={12} sm={6} md={4} key={i} >
          <Box sx={{ marginRight: 0.5, my: 1, minHeight: "200px", position: "relative", opacity: (n?.available ? 1 : 0.4) }} >
            {(n == null) && (getSkeleton())}
            {(n != null) && (<>
              <Card onClick={() => { getGuideInfo(n) }} style={{ borderRadius: "10px" }}>
                <GuideImages data={n} minimalView={true} />
                <CardContent style={{ background: "#eee", padding: "10px" }} >
                  <Grid container style={{ background: "#fff", padding: "10px", minHeight: "160px", borderRadius: "0 0 10px 10px" }} >
                    <Grid container paddingBottom={1} spacing={1}>
                      <Grid item xs={12} display={"flex"}>
                        <Stack direction={"row"} alignContent={"center"} alignItems={"center"}>
                          <Typography gutterBottom variant="h6" component="div"  > {n.guideName} </Typography>
                          <Typography variant="body2" color="text.secondary" component="div">
                            {(n.age) ? ", " + n.age : ""}  {(n.gender ? ", " + n.gender : "")}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack direction={"row"} display={"flex"}>
                          <Stack flexGrow={1}>
                            <Typography variant="body2" color="text.secondary">
                              Price: {n.price}฿ for 6 hours
                            </Typography>
                            <Typography style={{ marginTop: "9px" }} variant="body2" color="text.secondary" > Location: {n.location ?? "-"} </Typography>
                          </Stack>
                          {(!n.available) ? (<></>) : (<BookNow data={n} bookingDate={date} />)}
                        </Stack>
                      </Grid>
                    </Grid>
                    <Grid container paddingBottom={1}>
                      <Grid item xs={12}>
                        <Typography variant="body2" color="text.secondary">
                          I speak: {n.languages.join(",")}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container paddingBottom={1}>
                      <Grid item xs={12}>
                        <Typography variant="body2" color="text.secondary" >
                          {description.length > 100 ? `${n.description.substring(0, 95)}...` : description}
                        </Typography></Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </>)
            }
          </Box>
        </Grid >)

    })}

  </Grid >)
}

export default ResultView;