import { AppBar, AppBarProps, Button, ButtonProps, Fab, FabProps, Typography, TypographyProps } from "@mui/material";
import { amber, purple } from "@mui/material/colors";
import { styled } from "@mui/material/styles";




export const CustomFab = styled(Fab)<FabProps>(({ theme }) => ({
    color: "#000",
    backgroundColor: amber[400],

    '&:hover': {
        backgroundColor: amber[700],
    },
}));



export const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: "#000",
    backgroundColor: amber[400],

    '&:hover': {
        backgroundColor: amber[700],
    },
}));


export const CustomNameLabel = styled(Typography)<TypographyProps>(({ theme }) => ({
    display: "block",
    color: "#fff",
    WebkitTextStroke: "1px rgb(155 145 145)",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
}));


export const CustomAppBar = styled(AppBar)<AppBarProps>(({ theme }) => ({
    color: "#000",
    backgroundColor: "#fff",

    '&:hover': {
        backgroundColor: "#fff",
    },
}));


export const BookNowButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: "#000",
    backgroundColor: amber[400],
    border: "2px solid #000",
    borderRadius: "15px",
    marginTop: "5px",
    '&:hover': {
        backgroundColor: amber[700],
    },
}));
